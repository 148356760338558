import React from "react";
import theme from "theme";
import { Theme, Box, Text, Section, Image, Link } from "@quarkly/widgets";
import { Helmet } from "react-helmet";
import { GlobalQuarklyPageStyles } from "global-page-styles";
import { RawHtml, Override } from "@quarkly/components";
import * as Components from "components";
export default (() => {
	return <Theme theme={theme}>
		<GlobalQuarklyPageStyles pageUrl={"index"} />
		<Helmet>
			<title>
				Головна | Паб та пивоварня Бургомістр
			</title>
			<meta name={"description"} content={"У Бургомістр ми поєднали азарт гри з пивоварним ремеслом. Приєднуйтесь до нас для незабутнього спортивного та пивного досвіду, який не схожий ні на який інший."} />
			<meta property={"og:title"} content={"Головна | Паб та пивоварня Бургомістр"} />
			<meta property={"og:description"} content={"У Бургомістр ми поєднали азарт гри з пивоварним ремеслом. Приєднуйтесь до нас для незабутнього спортивного та пивного досвіду, який не схожий ні на який інший."} />
			<meta property={"og:image"} content={"https://opamakinst.com/img/banner.jpg"} />
			<link rel={"shortcut icon"} href={"https://opamakinst.com/img/2559186-200.png"} type={"image/x-icon"} />
			<link rel={"apple-touch-icon"} href={"https://opamakinst.com/img/2559186-200.png"} />
			<link rel={"apple-touch-icon"} sizes={"76x76"} href={"https://opamakinst.com/img/2559186-200.png"} />
			<link rel={"apple-touch-icon"} sizes={"152x152"} href={"https://opamakinst.com/img/2559186-200.png"} />
			<link rel={"apple-touch-startup-image"} href={"https://opamakinst.com/img/2559186-200.png"} />
			<meta name={"msapplication-TileImage"} content={"https://opamakinst.com/img/2559186-200.png"} />
			<meta name={"msapplication-TileColor"} content={"https://opamakinst.com/img/2559186-200.png"} />
		</Helmet>
		<Components.Header />
		<Section
			min-height="100vh"
			lg-padding="120px 0 88px 0"
			md-padding="140px 0 96px 0"
			sm-padding="60px 0 96px 0"
			quarkly-title="Hero-23"
			padding="88px 0 88px 0"
			background="radial-gradient(50% 50% at 52.09% 50%,rgba(4, 8, 12, 0.5) 67.71%,rgba(4, 8, 12, 0.6) 100%),url(https://opamakinst.com/img/banner.jpg) center center/cover no-repeat"
		>
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				margin="0px 0px 0px 0px"
				padding="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				lg-flex-direction="column"
				display="flex"
				lg-width="100%"
				align-items="flex-start"
				lg-align-items="center"
				lg-justify-content="center"
				width="100%"
				flex-direction="row"
			/>
			<Box
				margin="0px 0px 0px 0px"
				md-margin="0px 0px 0px 0px"
				lg-justify-content="center"
				display="flex"
				lg-align-items="center"
				align-items="flex-end"
				lg-width="100%"
				padding="0px 0px 0px 0px"
				justify-content="space-between"
				lg-flex-direction="column"
				width="100%"
				flex-direction="row"
			>
				<Text
					sm-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					width="25%"
					md-width="100%"
					margin="0px 0px 0px 0px"
					lg-width="100%"
					color="--light"
					font="--headline2"
					md-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
					lg-text-align="center"
					lg-margin="0px 0px 20px 0px"
					lg-font="normal 700 42px/1.2 &quot;Source Sans Pro&quot;, sans-serif"
				>
					Бургомістр
					<br />
					Паб та пивоварня{" "}
				</Text>
				<Text
					color="#FFFFFF"
					font="--base"
					width="25%"
					lg-width="100%"
					lg-text-align="center"
					margin="0px 0px 0px 0px"
				>
					У Бургомістр ми поєднали азарт гри з пивоварним ремеслом. Приєднуйтесь до нас для незабутнього спортивного та пивного досвіду, який не схожий ні на який інший.
				</Text>
			</Box>
		</Section>
		<Section text-align="center" padding="80px 0" sm-padding="40px 0">
			<Text as="h1" font="--headline1" md-font="--headline2" margin="20px 0 0 0">
				Про нас
			</Text>
			<Text as="p" font="--lead" margin="20px 0 0 0">
				Паб та пивоварня "Бургомістр" - це більше, ніж просто спортивний бар, це свято спорту та пивної культури. Ми ретельно створили атмосферу, яка об'єднує вболівальників за крафтовими сортами пива та любов'ю до гри.
			</Text>
			<Box display="flex" margin="40px 0 20px 0" justify-content="space-around" sm-flex-direction="column">
				<Box padding="10px">
					<Image src="https://opamakinst.com/img/1.jpg" width="320px" max-width="100%" />
				</Box>
				<Box padding="10px">
					<Image src="https://opamakinst.com/img/2.jpg" width="320px" max-width="100%" />
				</Box>
				<Box padding="10px">
					<Image src="https://opamakinst.com/img/3.jpg" width="320px" max-width="100%" />
				</Box>
			</Box>
		</Section>
		<Section padding="80px 0 80px 0" sm-padding="60px 0 60px 0">
			<Override slot="SectionContent" flex-direction="row" md-flex-wrap="wrap" flex-wrap="wrap" />
			<Box width="100%" margin="0px 0px 64px 0px" md-margin="0px 0px 30px 0px">
				<Text
					margin="0px 0px 0px 0px"
					color="--dark"
					font="--headline2"
					width="45%"
					lg-width="100%"
					lg-text-align="center"
				>
					Пиво в день гри
				</Text>
			</Box>
			<Box display="flex" width="100%" md-flex-wrap="wrap">
				<Box
					width="48.5%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					padding="0px 0px 0px 0px"
					md-padding="0px 0px 0px 0px"
					md-margin="0px 0px 40px 0px"
					margin="0px 3% 0px 0px"
				>
					<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base" md-text-align="center">
						Наша пивоварня пропонує різноманітні сорти пива ручної роботи, що гарантує, що ви завжди зможете спробувати щось новеньке.
					</Text>
				</Box>
				<Box
					width="48.5%"
					display="flex"
					flex-direction="column"
					justify-content="space-between"
					md-width="100%"
					padding="0 0px 0 0px"
				>
					<Text margin="0px 0px 0px 0px" color="--darkL2" font="--base" md-text-align="center">
						Ми пропонуємо широкий вибір місцевого та імпортного пива на розлив, яке ідеально підходить для потягу, поки ви вболіваєте за свою команду.
					</Text>
				</Box>
			</Box>
		</Section>
		<Section padding="65px 0 65px 0" sm-padding="60px 0 60px 0" quarkly-title="Images-8">
			<Override slot="SectionContent" flex-direction="row" flex-wrap="wrap" />
			<Box
				sm-margin="0px 0px 30px 0px"
				flex-direction="column"
				display="flex"
				width="33.333%"
				lg-width="100%"
				margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				sm-padding="0px 0px 0px 0px"
			>
				<Box
					width="100%"
					overflow-x="hidden"
					overflow-y="hidden"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					padding="0px 0px 140% 0px"
					height="auto"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						left={0}
						src="https://opamakinst.com/img/4.jpg"
						object-fit="cover"
						position="absolute"
						display="block"
						width="100%"
						top="auto"
						right={0}
						bottom="0px"
						min-height="100%"
					/>
				</Box>
			</Box>
			<Box
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				width="33.333%"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-order="1"
				display="flex"
			>
				<Box
					padding="0px 0px 140% 0px"
					width="100%"
					overflow-y="hidden"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					height="auto"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						width="100%"
						bottom="0px"
						min-height="100%"
						display="block"
						top="auto"
						left={0}
						right={0}
						src="https://opamakinst.com/img/5.jpg"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
			</Box>
			<Box
				align-items="flex-start"
				lg-align-items="center"
				lg-margin="0px 0px 0px 0px"
				padding="16px 16px 16px 16px"
				flex-direction="column"
				width="33.333%"
				lg-width="100%"
				sm-margin="0px 0px 0px 0px"
				sm-padding="0px 0px 0px 0px"
				lg-order="1"
				display="flex"
			>
				<Box
					padding="0px 0px 140% 0px"
					width="100%"
					overflow-y="hidden"
					transform="translateY(0px)"
					hover-transform="translateY(-10px)"
					height="auto"
					overflow-x="hidden"
					position="relative"
					transition="transform 0.2s ease-in-out 0s"
				>
					<Image
						width="100%"
						bottom="0px"
						min-height="100%"
						display="block"
						top="auto"
						left={0}
						right={0}
						src="https://opamakinst.com/img/6.jpg"
						object-fit="cover"
						position="absolute"
					/>
				</Box>
			</Box>
		</Section>
		<Box min-width="100px" min-height="100px" text-align="center">
			<Link
				href="/contact-us"
				color="#000000"
				padding="12px 24px 12px 24px"
				text-decoration-line="initial"
				background="--color-green"
				margin="20px 0px 40px 0px"
				border-radius="8px"
				font="--lead"
			>
				Знайти Нас
			</Link>
		</Box>
		<Components.Footer />
		<RawHtml>
			<style place={"endOfHead"} rawKey={"65323428c5fd0f0020b15081"}>
				{":root {\n  box-sizing: border-box;\n}\n\n* {\n  box-sizing: inherit;\n}"}
			</style>
		</RawHtml>
	</Theme>;
});